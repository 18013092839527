import React from "react"
import Helmet from "react-helmet"

import "../style.css"
import Entheo from "../images/entheo.png"
import LeafShyp from "../images/leafshyp.png"

export default () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Entheo Brand</title>
        </Helmet>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2rem',
            }}>
                <img style={{maxWidth: '100%', width: '640px'}} src={Entheo} alt="Entheo" />
                <a href="https://leafshyp.com">
                    <img style={{maxWidth: '100%', width: '340px', marginTop: '4rem'}} src={LeafShyp} alt="LeafShyp" /> 
                </a>
                <p style={{ maxWidth: '32em', fontFamily: 'sans-serif', fontSize: '1.5em', marginTop: '4rem'}}>
                    California Proposition 64 and the resulting legal climate regarding retail cannabis within the state of California has forced us to shut our doors. We will continue to advocate for patient rights and hope to serve you again in the near future. <br /><br />For inquiries, please contact us at <a href="mailto:info@entheobrand.com">info@entheobrand.com</a>  
                </p>
            </div>
        </div>
    </>
)
